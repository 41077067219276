import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { fontstack } from "utils/fontstack";
import { gsap } from "gsap";
import { media } from "utils/mediaQuery";


const Wrapper = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-base);
  line-height: 1.18;
  font-weight: 400;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  padding: 18px;
  display: inline-block;
  position: relative;
  transition: border .2s ease;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    filter: blur(10px);
    transition: background .2s ease;
    z-index: 0;
  }

  &:active {
    border: 1px solid transparent;
    color: var(--brand-black);
  }

  &:active:after {
    background: white;
  }

  ${media.medium`
    &:hover {
      border: 1px solid transparent;
      color: var(--brand-black);
    }

    &:hover:after {
      background: white;
    }
  `}

 
`

const Label = styled.div`
  position: relative;
  z-index: 1;
`;

const BlurButton = ({children, ...rest}) => {

  return (
    <Wrapper {...rest}>
      <Label>
        {children}
      </Label>
    </Wrapper>
  )
}

export default BlurButton;
