import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SectionMargin } from "components/Section/Section";

const VideoTag = styled.video.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    defaultValidatorFn(prop) || ['webkit-playsinline'].includes(prop) || ['x5-playsinline'].includes(prop) || ['x5-video-player-type'].includes(prop) || ['x5-video-orientation'].includes(prop),
})` 
  position: relative;
  width: 100%;
  display: block;

  ${({cover}) => cover && `
    object-fit: cover;
    height: 100%;
  `}
`;

const Video = React.forwardRef( ({src, cover, ...rest}, ref) => {

  return (
    <VideoTag ref={ref} {...rest} cover={cover} controlsList="nodownload noplaybackrate" webkit-playsinline="true" x5-video-player-type="h5" disablePictureInPicture>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </VideoTag>
  )
});

export default Video;
