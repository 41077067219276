import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import { SectionMargin } from "components/Section/Section";
import { gsap } from "gsap";
import { SplitText } from "gsap/all";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";


const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 15px;

  ${media.medium` 
    padding: 50px 30px;
  `}
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${media.medium` 
    display: block;
  `}
`;

const LineWrapper = styled(Grid)`
  flex: 1;
  display: flex;
  align-items: center;

  ${media.medium` 
    display: block;
    margin: 10vw 0 0;
  `}
`;

const Col = styled.div`
  grid-column: 1 / span 6;

  ${media.medium` 
    grid-column: 2 / span 11;
  `}
`

const LineText = styled.h2`
  ${fontstack.default}
  font-size: var(--font-size-lg);
  line-height: 1;
  font-weight: 400;
  margin: 0;
  color: white;
`

const Statements = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: grid;
  flex: 1;
  overflow: hidden;
`;

const StatementText = styled(LineText).attrs({
  as: "li"
})` 
  grid-area: 1 / 1;
  padding: 0 0 10px;
  
  ${media.medium` 
    padding: 0 0 20px;
  `}
`;

const BottomStatementGrid = styled(Grid)` 
  position: relative;
  bottom: 0;
  left: 0;
  
  ${media.medium` 
    position: relative;
    margin: 120px 0 0;
  `}
`;

const BottomStatement = styled.p`
  margin: 0;
  grid-column: 1 / span 6;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  border-top: 1px solid #fff;
  padding: 1.8vw 0 0;

  ${media.medium` 
    grid-column: 9 / span 3;
  `}
`;

const RenGradient = styled(StatementText)`
  background: linear-gradient(180deg, #B060FF 0%, #12EDFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const PushingGradient = styled(StatementText)`
  background: linear-gradient(180deg, #D2FF6C 0%, #52CBC6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const TeamGradient = styled(StatementText)`
  background: linear-gradient(180deg, #4BEF9D 0%, #1568D9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const HomeTitle = () => {
  const { IntroComplete } = React.useContext(SiteContext);

  React.useEffect(() => {
    let tl;
    
    if(IntroComplete) {
      gsap.set("#statements li", { opacity: 0})
      tl = gsap.timeline({repeat:-1})
      const lines = gsap.utils.toArray('#statements li')
  
      lines.forEach((line, i) => {
        tl.to(line, {
          opacity: 1,
          ease: "back.inOut(1)",
        })
        .to(line, {
          y: "-100%",
          ease: "back.inOut(1)",
          delay: 3
        });
      })
    }
    
    return () => {
      if(tl) {
        tl.kill();
      }
    }
  }, [IntroComplete]);

  return (
    <Wrapper>
      <InnerWrapper>
        <LineWrapper>
          <Col>
            <LineText>We're&nbsp;</LineText>
            <Statements id="statements">
              <TeamGradient>a team of scientists<br/>+ creatives.</TeamGradient>
              <PushingGradient>pushing the boundaries<br/>of creative technology.</PushingGradient>
              <RenGradient>REN.</RenGradient>
            </Statements>
          </Col>
        </LineWrapper>
        <BottomStatementGrid>
          <BottomStatement>
          A multidimensional creative studio specialising in the fields of design,<br/>film and visual arts.
          </BottomStatement>
        </BottomStatementGrid>
      </InnerWrapper>
    </Wrapper>
  )
}

export default HomeTitle;
