import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { Link } from "gatsby";
import { SectionMargin, PadSection } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import Newsticker from "modules/Newsticker/Newsticker";
import HomeFeaturedVideo from "modules/HomeFeaturedVideo/HomeFeaturedVideo";
import MaskButton from "components/MaskButton/MaskButton";
import { media } from "utils/mediaQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.div`
  position: relative;
  padding: 100px 0 0;
  text-align: center;
  z-index: 3;
  

  ${media.medium`
    padding: 50vh 0 0;
    overflow: hidden;
  `}
`;

const NewstickerWrapper = styled.div`
  display: grid;
`;

const NewstickerLayer = styled.div`
  grid-area: 1 / 1;
  width: 100%;
  overflow: hidden;
`;

const SectionNameGrid = styled(Grid)`
  
`

const SectionNamePad = styled(PadSection)`
  position: relative;
  width: 100%;
`

const SectionName = styled.div`
  grid-column: 2 / span 3;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  text-align: left;
`;

const Title = styled.h2`
  ${fontstack.default}
  font-weight: 400;
  margin: 6vw 0 10vw;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: -10vw 0 0;
`

const FeaturedVideos = ({nodes}) => {
  
  React.useLayoutEffect(() => {

    gsap.set("#news-layer-two", { opacity: 0 })

    var tl = gsap.timeline({
      scrollTrigger: {
          trigger: ".ticker-wrapper",
          id: "ticker-movement-trigger",
          start: "center center",
          end: "center 15%",
          endTrigger: ".last-featured",
          pin: true,
          pinSpacing: false,
          scrub: true,
          markers: false
      }
    });

    tl.add('move')
    .to('.news-one_top', {
      duration: 1,
      x: 2000,
    }, 'move').to('.news-one_bottom', {
      duration: 1,
      x: -2000,
    }, 'move')
    .to('.news-two_top', {
      duration: 1,
      x: 2000,
    }, 'move').to('.news-two_bottom', {
      duration: 1,
      x: -2000,
    }, 'move');

    const trigger = ScrollTrigger.create({
      trigger: ".last-featured",
      id: "ticker-switch-trigger",
      start: 'top 90%',
      markers: false,
      onEnter: () => {
        gsap.to("#news-layer-one", { opacity: 0, duration: 0.2})
        gsap.to("#news-layer-two", { opacity: 1, duration: 0.2})
      },
      onLeaveBack: () => {
        gsap.to("#news-layer-one", { opacity: 1, duration: 0.2})
        gsap.to("#news-layer-two", { opacity: 0, duration: 0.2})
      }
    });

    var lasTl = gsap.timeline({
      scrollTrigger: {
          trigger: ".last-featured",
          id: "ticker-shift-trigger",
          start: "center 15%",
          end: "+=1000",
          scrub: true,
          markers: false
      }
    });

    lasTl
    .to('#news-layer-two', {
      y: -200,
    });
  
    return () => {

      const movementInstance = ScrollTrigger.getById("ticker-movement-trigger");
      if(movementInstance) {
        movementInstance.kill();
      }

      const switchInstance = ScrollTrigger.getById("ticker-switch-trigger");
      if(switchInstance) {
        switchInstance.kill();
      }

      const shiftInstance = ScrollTrigger.getById("ticker-shift-trigger");
      if(shiftInstance) {
        shiftInstance.kill();
      }

      if(tl) {
        tl.kill();
      }

      if(lasTl) {
        lasTl.kill();
      }

      if(trigger) {
        trigger.kill();
      }
    }
  }, []);

  return (
    <Wrapper> 
      <SectionNamePad>
          <Title>Featured Projects (2)</Title>
        </SectionNamePad>
      <NewstickerWrapper className="ticker-wrapper">
        <NewstickerLayer id="news-layer-one">
          <Newsticker segment={nodes[0].title} className="news-one_top"/>
          <Newsticker segment={nodes[0].tags[0].label} className="news-one_bottom" reversed/>
        </NewstickerLayer>
        <NewstickerLayer id="news-layer-two">
          <Newsticker segment={nodes[1].title} className="news-two_top"/>
          <Newsticker segment={nodes[1].tags[0].label} className="news-two_bottom" reversed/>
        </NewstickerLayer>
      </NewstickerWrapper>
      { nodes && nodes.map((node,i) => {

        return (
          <HomeFeaturedVideo
          key={node.slug.current} 
          category={node.category.label} 
          year={node.year ? node.year.label : null}
          src={node.mainVideo}
          slug={node.slug.current}
          previewSrc={node.thumbnail.video}
          previewMobileSrc={node.thumbnail.mobileVideo}
          className={i === nodes.length - 1 ? "last-featured" : null}/>
        )
      })}
    </Wrapper>
  )
}

export default FeaturedVideos;
