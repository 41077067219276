import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import { StaticImage } from "gatsby-plugin-image";
import { SectionMargin, PadSection } from "components/Section/Section";
import Brand from "components/Brand/Brand";
import ArrowIcon from "./assets/arrow.svg";
import { fontstack } from "utils/fontstack";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Video from "modules/Video/Video";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  overflow: hidden;
`

const LogoFrame = styled.div`
  grid-area: 1 / 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const LogoWrapper = styled.div`
  width: 100px;
`

const VideoFrame = styled.div`
  grid-area: 1 / 1;
  width: 100%; 
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  transform: scale(0.3) translate(0, 300%);
  z-index: 0;
`;

const VideoFrameInner = styled.div`
  width: 100%; 
  height: 100%;
  position: relative;
`

const ArrowFrame = styled.div`
  grid-area: 1 / 1;
  width: 100%; 
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px;

`

const Intro = () => {
  const { Smoother, setIntroComplete } = React.useContext(SiteContext);
  const [ Visible, setVisible ] = React.useState(true);
  const [ VideoObject, setVideo ] = React.useState();
  const startTl = React.useRef();

  React.useLayoutEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
          id: "intro-trigger",
          trigger: "#intro-wrapper",
          start: "top top",
          end: "+=1200",
          pin: true,
          scrub: true
      }
    });
 
     tl
     .add("videoLarge")
     .to("#video-frame-inner", {
       duration: 1.7,
       scale: 1,
     }, "videoLarge")
     .to("#video-frame", {
       duration: 1.7,
       scale: 1,
       borderRadius: 0,
       ease: "back.inOut"
     }, "videoLarge");

     return () => {
      const triggerInstance = ScrollTrigger.getById("intro-trigger");
      if(triggerInstance) {
        triggerInstance.kill();
      }
      
      if(tl) {
        tl.kill();
      }
     }
  },[])

  React.useLayoutEffect(() => {
    document.body.style.position = 'fixed';
    let tlFadeIn, tl;
    if(VideoObject) {
      tlFadeIn = gsap.timeline();

      tlFadeIn.to("#logo-frame", {
         duration: 4,
         opacity: 1,
         ease: "ease.inOut",
       });

       startTl.current = gsap.timeline();
       startTl.current.add("videoIn")
       .to("#video-frame-inner", {
         duration: 1.7,
         delay: 4,
         scale: 1.5,
       }, "videoIn")
       .to("#video-frame", {
         duration: 1.7,
         delay: 4,
         y: "0%",
         ease: "back.inOut",
         onComplete: () =>  {
          setVisible(false);
          setIntroComplete(true);
          document.body.style.position = 'relative';
          ScrollTrigger.refresh();
         }
       }, "videoIn")
       .to("#logo-frame", {
        duration: .4,
        opacity: 0,
        ease: "ease.inOut",
      });

    }
      
    
    return () => {

      if(tlFadeIn && startTl.current) {
        tlFadeIn.kill();
        startTl.current.kill();
      }

    }

  },[VideoObject])

  const handleMetaData = (event) => {
    console.log("meta loaded");
    console.log(event);
  }

  const videoEl = React.useCallback(node => {
    if (node !== null) {
      setVideo(node);       
    }
  }, []);

  return (
    <>
    <Wrapper id="intro-wrapper">
    <LogoFrame id="logo-frame">
      <LogoWrapper>
        <Brand/>
      </LogoWrapper>
    </LogoFrame>
    <VideoFrame id="video-frame">
      <VideoFrameInner id="video-frame-inner">
        <Video ref={videoEl} onLoadedMetadata={handleMetaData} src="https://ren-studios-storage.oss-cn-hongkong.aliyuncs.com/welcome_to_ren-short.mp4" playsInline autoPlay muted loop cover/>
      </VideoFrameInner>
    </VideoFrame>
    <ArrowFrame>
      <ArrowIcon />
    </ArrowFrame>
  </Wrapper>
  </>
  )
}

export default Intro;
