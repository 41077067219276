import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { PadSection, SectionMargin } from "components/Section/Section";
import { gsap } from "gsap";
import { SplitText, ScrollTrigger } from "gsap/all";
import { Link } from "gatsby";
import GlowButton from "components/GlowButton/GlowButton";
import MaskButton from "components/MaskButton/MaskButton";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";

gsap.registerPlugin(SplitText,ScrollTrigger);

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 120px 0;
  background: var(--brand-white);
  z-index: 4;

  ${media.medium` 
    padding: 10vw 0 12vw;
  `}
`;

const CustomGrid = styled(Grid)`

`

const BorderCol = styled.div`
  grid-column: 1 / span 6;
  height: 1.5px;
  background: black;
  z-index: 3;

  ${media.medium` 
    grid-column: 3 / span 8;
  `}
`

const Copy = styled.div` 
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  margin: 0;
  color: var(--brand-black);
  grid-column: 1 / span 6;
  z-index: 3;

  ${media.medium` 
    grid-column: 3 / span 4;
  `}
`;

const ExploreCol = styled.div`
  display: none;
  grid-column: 9 / span 2;
  z-index: 3;

  ${media.medium` 
    display: block;
  `}
`

const MobileExploreCol = styled.div`
  display: block;
  grid-column: 1 / span 6;
  z-index: 3;

  ${media.medium` 
    display: none;
  `}
`

const Col = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;

  ${media.medium` 
    grid-column: 3 / span 8;
  `}
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  top: 15px;
  background: black;
`

const GradientMask = styled.div`
  position: absolute;
  display: block;
  background: linear-gradient(170deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
  width: 100%;
  left: 0;
  height: 220%;
  top: 0px;
  transform: translate(0,-55%);
  z-index: 2;
`

const CopySection = styled.p`
  ${fontstack.default}
  font-weight: 400;
  margin: 60px 0;
  font-size: var(--font-size-lg);
  line-height: 1.08;
  background: linear-gradient(180deg, #000000 0%, #D880FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: none;

  ${media.medium` 
    margin: 4vw 0 0;
    display: block;
  `}
`;

const MobileCopySection = styled(CopySection)`
  display: block;
  ${media.medium` 
    display: none;
  `}

`


const FindOut = () => {

  React.useEffect(() => {
    let tl;

    tl = gsap.timeline({
      scrollTrigger: {
          trigger: "#findout-wrapper",
          id: "findout-trigger",
          start: "top 60%",
          end: "+=800",
          scrub: true,
          markers: false
      }
    });

    tl
    .to('#findout-gradient', {
      duration: 1,
      y: "50%",
    })
    return () => {
      const triggerInstance = ScrollTrigger.getById("findout-trigger");
      if(triggerInstance) {
        triggerInstance.kill();
      }
      if(tl) {
        tl.kill();
      }
    }
  }, []);

  return (
    <Wrapper id="findout-wrapper">
      <PadSection>
      <CustomGrid>
        <BorderCol />
        <Copy>An interactive form whereby answering two questions can formulate a series of answers
that pertain to your professional needs.</Copy>
        <ExploreCol>
          <Link to="/explore">
            <MaskButton theme="light" noarrow sidecar>Explore</MaskButton>
          </Link>
        </ExploreCol>
        <Col>
          <GradientMask id="findout-gradient"/>
          <MobileCopySection>
            2 questions. A wide-range of possibilites.
          </MobileCopySection>
          <CopySection>
            2 questions.<br/>
            A wide-range<br/>
            <span>of possibilites.</span>
          </CopySection>
          
        </Col>
        <MobileExploreCol>
          <Link to="/explore">
            <MaskButton theme="light" noarrow sidecar>Explore</MaskButton>
          </Link>
        </MobileExploreCol>
        
     
      </CustomGrid>
      </PadSection>
    </Wrapper>
  )
}

export default FindOut;
