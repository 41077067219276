import React from "react";
import styled from "styled-components";
import { fontstack } from "utils/fontstack";

const Main = styled.div`
  font-size: var(--font-size-lg);
  font-weight: 400;
`;

const Wrapper = styled.div`
  ${fontstack.default}
  position: relative;
  color: var(--brand-white);
  display: inline-block;
  line-height: 1;
  z-index: 1;

  ${({gradient}) => gradient && `
    background: linear-gradient(180deg, ${gradient[0]} 0%, ${gradient[1]} 100%);
  `}

  -webkit-background-clip: text;
  -webkit-text-fill-color: ${(props) => props.isWhite ? "unset" : "transparent"};

  &:hover > ${Main}{
    filter: drop-shadow(0px 0px 6px white);
  }
`;

const Amount = styled.div`
  font-size: var(--font-size-base);
  text-align: left;
`

const GradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

const CategoryLabel = ({ children, amount, amtClass, gradient, isWhite }) => {
  return (
    <Wrapper gradient={gradient} isWhite={isWhite}>
      <Main>{ children }</Main>
      { amount && !isWhite ? <Amount className={amtClass}>{amount}</Amount> : null}
    </Wrapper>
  ) 
}

export default CategoryLabel;

const DummyWrapper = styled.div`
  ${fontstack.default}
  position: absolute;
  color: var(--brand-white);
  display: inline-block;
  line-height: 1;
  z-index: 2;
  opacity: 0;
`

const DummyAmount = styled.div`
  font-size: var(--font-size-base);
  color: var(--brand-white);
  text-align: left;
`

const CategoryLabelDummy = ({children, amount, amtClass, isWhite, ...rest}) => {

  return (
    <>
    <DummyWrapper {...rest}>
      <Main>
        { children }
      </Main>
      { amount && !isWhite ? <DummyAmount className={amtClass}>{amount}</DummyAmount> : null}
    </DummyWrapper>
    </>
  )
}

export { CategoryLabelDummy }

const MobileWrapper = styled.div`
  ${fontstack.default}
  position: relative;
  color: var(--brand-white);
  display: flex;
  justify-content: center;
  line-height: 1;
  z-index: 1;
  width: 100%;
`;

const MobileInner = styled.div`
  position: relative;
  margin: 50px 0;
  display: inline-flex;
  flex-direction: column;
`

const MobileLabel = styled.div`
  font-size: var(--font-size-lg);
  font-weight: 400;
`;

const MobileCategoryLabel = ({ children, amount, amtClass, gradient }) => {
  return (
    <MobileWrapper gradient={gradient}>
      <MobileInner>
        <MobileLabel>{ children }</MobileLabel>
        { amount ? <Amount className={amtClass}>{amount}</Amount> : null}
      </MobileInner>
    </MobileWrapper>
  ) 
}

export { MobileCategoryLabel }