import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { Link } from "gatsby";
import CategoryLabel, { CategoryLabelDummy, MobileCategoryLabel } from "components/CategoryLabel/CategoryLabel";
import { PadSection } from "components/Section/Section";
import { gsap } from "gsap";
import { media } from "utils/mediaQuery";
import { ScrollTrigger } from 'gsap/all';
import { fontstack } from "utils/fontstack";


const Outer = styled.div`
  display: none;
  position: relative;
  z-index: 100;
  padding: 40px 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: none;

  ${media.medium`
    display: block;
  `}
`;


const Wrapper = styled(PadSection)`
  width: 100%;
  height: 100%;
  margin: 0 0 50vh;
  overflow: hidden;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  pointer-events: auto;
  transform-origin: center top;
`;

const CustomGrid = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MenuBackground = styled.div` 
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: rgba(255,255,255,0.2);
  backdrop-filter: blur(30px) brightness(115%);
  filter: drop-shadow(0 0 8px rgb(0 0 0 / 1));
  z-index: 0;
`;

const GradientCursor = styled.div`  
  position: absolute;
  height: calc(100% + 100px);
  width: calc(100% + 100px);
  z-index: 0;
  top: -50px;
  left: -50px;
  opacity: 0;
  transition: opacity .4s ease;
  ${({gradient}) => gradient && `
    background: linear-gradient(135deg, ${gradient[0]}, ${gradient[1]});
  `}

  filter: blur(80px);
  border-radius: 8px;
`;


const LaneInner = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${GradientCursor} {
    opacity: 1;
  }
  
`;

const Lane = styled.div`
  position: relative;
  height: 100%;
  z-index: 2;
  text-align: left;
`;

const LaneMiddle = styled(Lane)`
  text-align: center;
`;

const LaneLast = styled(Lane)`
  text-align: right;
`;

const SectionNamePad = styled(PadSection)`
  width: 100%;
  margin: 0 0 6vw;
`

const Title = styled.h2`
  ${fontstack.default}
  font-weight: 400;
  margin: 6vw 0 10vw;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  text-align: center;
`

const SectionName = styled.div`
  grid-column: 2 / span 3;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  text-align: left;
`;

const Separator = styled.div` 
  height: 100%;
  width: 1px;
`;

const SeparatorLine = styled.div` 
  position: absolute;
  width: 1px;
  height: 1000%;
  background: white;
  display: block;
  bottom: 0;
`

const MobileWrapper = styled(PadSection)`
  ${media.medium`
    display: none;
  `}
`

const MobileCol = styled.div`
  grid-column: 1 / span 6;
`

const MobileLabelWrapper = styled.div`
  position: relative;
  margin: 50px 0;
`

const MobileGradientCursor = styled.div`  
  position: absolute;
  height: 90%;
  width: 90%;
  z-index: 0;
  top: 5%;
  left: 5%;
  opacity: 0;
  transition: opacity .4s ease;
  will-change: opacity;
  ${({gradient}) => gradient && `
    background: linear-gradient(135deg, ${gradient[0]}, ${gradient[1]});
  `}

  filter: blur(30px);
  border-radius: 8px;
`;

const CategoryMenu = ({biotechCount,techCount,createCount}) => {
  const [ isWhite, setIsWhite ] = React.useState(false);

  React.useLayoutEffect(()=>{
    let mm = gsap.matchMedia();
    let trigger, tl;
    let mobileTl = null;

    mm.add("(min-width: 900px)", () => {
      gsap.set("#category-menu-middle-separator", { y: "50%" });
      gsap.set("#category-menu-middle-separator-inner", { y: "-50%" })
      gsap.set("#category-menu-middle", { y: "50%" });
      gsap.set("#category-menu-middle-inner", { y: "-50%" })
      gsap.set("#category-menu-last-separator", { y: "100%" });
      gsap.set("#category-menu-last-separator-inner", { y: "-100%" })
      gsap.set("#category-menu-last", { y: "100%" });
      gsap.set("#category-menu-last-inner", { y: "-100%" })
      gsap.set("#category-menu-bg", { opacity: 0 })
      trigger = ScrollTrigger.create({
        trigger: "#category-menu-outer",
        id: "desktop-trigger-one",
        start: "top top",
        endTrigger: "html",
        end: "bottom bottom",
        pin: true,
        fastScrollEnd: true,
        pinSpacing: false,
      });

      tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#category-menu-outer",
            id: "desktop-trigger-two",
            start: "top top",
            end: "+=1000",
            fastScrollEnd: true,
            scrub: true,
        }
      });

      tl.add('middle')
      .to('#biotech-dummy', {
        duration: 1,
        opacity: 1
      }, 'middle')
      .to('#tech-dummy', {
        duration: 1,
        opacity: 1
      }, 'middle')
      .to('#category-menu-middle', {
        duration: 1,
        y: "0%"
      }, 'middle')
      .to('#category-menu-middle-inner', {
        duration: 1,
        y: "0%"
      }, 'middle')
      .to('#category-menu-middle-separator', {
        duration: 1,
        opacity: 0,
        y: "0%"
      }, 'middle')
      .to('#category-menu-middle-separator-inner', {
        duration: 1,
        y: "0%"
      }, 'middle')
      .add('last')
      .fromTo('#create-dummy', 
      { opacity: 0},
      {
        duration: 1,
        opacity: 1
      }, 'last')
      .to('#category-menu-last', {
        duration: 2,
        y: "0%"
      }, 'last')
      .to('#category-menu-last-inner', {
        duration: 2,
        y: "0%"
      }, 'last')
      .to('#category-menu-last-separator', {
        duration: 1,
        opacity: 0,
        y: "0%"
      }, 'last')
      .to('#category-menu-last-separator-inner', {
        duration: 1,
        y: "0%"
      }, 'last')
      .to("#category-menu-wrapper", {
        duration: 1,
        scale: 0.16,
        padding: "100px 100px",
      })
      .to("#category-menu-wrapper", {
        duration: 0.0001,
        height: "auto",
        margin: 0,
        onComplete: () => setIsWhite(true),
        onReverseComplete: () => setIsWhite(false)
      }).to("#category-menu-bg", {
        duration: 0.1,
        opacity: 1,
      });
 

      return () => { // optional
        const desktopOneTriggerInstance = ScrollTrigger.getById("desktop-trigger-one");
        const desktopTwoTriggerInstance = ScrollTrigger.getById("desktop-trigger-two");

        if(desktopOneTriggerInstance) {
          desktopOneTriggerInstance.kill();
        }

        if(desktopTwoTriggerInstance) {
          desktopTwoTriggerInstance.kill();
        }
      };

    })

    mm.add("(max-width: 899px)", () => {
      mobileTl = gsap.timeline({
        scrollTrigger: {
            id: "mobile-trigger",
            trigger: "#category-menu-mobile",
            endTrigger: "#category-menu-mobile",
            start: "top 75%",
            end: "bottom center",
            fastScrollEnd: true,
            scrub: true
        }
      });

      mobileTl
      .to('#mobile-gradient-cursor-biotech', {
        duration: 1,
        opacity: 1,
      })
      .to('#mobile-gradient-cursor-tech', {
        duration: 1,
        opacity: 1,
      })
      .to('#mobile-gradient-cursor-create', {
        duration: 1,
        opacity: 1,
      })


      return () => { // optional
        const mobileTriggerInstance = ScrollTrigger.getById("mobile-trigger");

        if(mobileTriggerInstance) {
          mobileTriggerInstance.kill();
        }
      };
    });


    

    return () => {
      const mobileTriggerInstance = ScrollTrigger.getById("mobile-trigger");
      if(mobileTriggerInstance) {
        mobileTriggerInstance.kill();
      }

      if(mobileTl) {
        mobileTl.kill();
      }

      if(tl) {
        tl.kill();
      }
     
      if(trigger) {
        trigger.kill();
      }
      
    }
  }, []);

  return (
    <>
      <SectionNamePad>
        <Title>Our (3) Disciplines</Title>
      </SectionNamePad>
      <MobileWrapper id="category-menu-mobile">
        <Grid>
          <MobileCol>
            <MobileLabelWrapper>
              <Link to="/work/biotech">
                <MobileCategoryLabel 
                  amount={biotechCount} 
                  gradient={['#079F76', '#1586D9']}>
                    BioTech
                </MobileCategoryLabel>
                <MobileGradientCursor id="mobile-gradient-cursor-biotech" gradient={['#079F76', '#1586D9']} />
              </Link>
            </MobileLabelWrapper>
            <MobileLabelWrapper>
              <Link to="/work/tech">
                <MobileCategoryLabel 
                  amount={techCount} 
                  gradient={['#0A1490', '#D880FF']}>
                    Tech
                </MobileCategoryLabel>
                <MobileGradientCursor id="mobile-gradient-cursor-tech" gradient={['#0A1490', '#D880FF']} />
              </Link>
            </MobileLabelWrapper>
            <MobileLabelWrapper>
              <Link to="/work/create">
                <MobileCategoryLabel 
                  amount={createCount} 
                  gradient={['#D97DF8', '#F00F0F']}>
                    Create
                </MobileCategoryLabel>
                <MobileGradientCursor id="mobile-gradient-cursor-create" gradient={['#D97DF8', '#F00F0F']} />
              </Link>
            </MobileLabelWrapper>
          </MobileCol>
        </Grid>
      </MobileWrapper>
      <Outer id="category-menu-outer">
        <Wrapper id="category-menu-wrapper">
          <Lane>
            <LaneInner>
              <Link to="/work/biotech">
                <CategoryLabelDummy id="biotech-dummy" amount={biotechCount}  isWhite={isWhite}>
                  BioTech
                </CategoryLabelDummy>
                <CategoryLabel 
                  amount={biotechCount} 
                  gradient={['#079F76', '#1586D9']}
                  isWhite={isWhite}>
                    BioTech
                </CategoryLabel>
                { isWhite && <GradientCursor gradient={['#079F76', '#1586D9']} /> }
              </Link>
            </LaneInner>
          </Lane>
          <Separator id="category-menu-middle-separator">
            <LaneInner id="category-menu-middle-separator-inner">
              <CategoryLabel amount={techCount}>
                Tech
                <SeparatorLine />                                        
              </CategoryLabel>
            </LaneInner>
          </Separator>
          <LaneMiddle id="category-menu-middle">
            <LaneInner id="category-menu-middle-inner">
              
              <Link to="/work/tech">
                <CategoryLabelDummy id="tech-dummy" amount={techCount} isWhite={isWhite}>
                  Tech
                </CategoryLabelDummy>
                <CategoryLabel 
                  amount={techCount}
                  gradient={['#0A1490', '#D880FF']}
                  isWhite={isWhite}>
                    Tech
                  </CategoryLabel>
                  { isWhite && <GradientCursor gradient={['#0A1490', '#D880FF']} />}
              </Link>
            </LaneInner>
          </LaneMiddle>
          <Separator id="category-menu-last-separator">
            <LaneInner id="category-menu-last-separator-inner">
              <CategoryLabel amount={techCount}>
                Create
                <SeparatorLine />                                        
              </CategoryLabel>
            </LaneInner>
          </Separator>
          <LaneLast id="category-menu-last">
            <LaneInner id="category-menu-last-inner">
              <Link to="/work/create">
                <CategoryLabelDummy id="create-dummy" amount={createCount} isWhite={isWhite}>
                  Create
                </CategoryLabelDummy>
                <CategoryLabel 
                  amount={createCount}
                  gradient={['#D97DF8', '#F00F0F']}
                  isWhite={isWhite}>
                    Create
                </CategoryLabel>
                { isWhite && <GradientCursor gradient={['#D97DF8', '#F00F0F']} /> }
              </Link>
            </LaneInner>
          </LaneLast>
          <MenuBackground id="category-menu-bg" />
        </Wrapper>
      </Outer>
    </>
    
  )
}

export default CategoryMenu;
