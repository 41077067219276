import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import { Link } from "gatsby";
import MaskButton from "components/MaskButton/MaskButton";
import { SectionMargin } from "components/Section/Section";
import { gsap } from "gsap";
import { SplitText, ScrollTrigger } from "gsap/all";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";

gsap.registerPlugin(SplitText);

const Wrapper = styled(SectionMargin)`
  position: relative;
  margin: 200px 0 190px;

  ${media.medium` 
    margin: 10vw 0 15vw;
  `}
`;

const CopySection = styled.p`
  ${fontstack.default}
  font-weight: 400;
  margin: 10vw 0 20vw;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;

  ${media.medium` 
    margin: 4vw 0 8vw;
  `}
`;

const BorderCol = styled.div`
  grid-column: 1 / span 6;
  height: 1px;
  background: white;

  ${media.medium` 
    grid-column: 2 / span 10;
  `}
`

const Col = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  ${media.medium` 
    grid-column: 3 / span 8;
    align-items: flex-end;
  `}
`

const GradientMask = styled.div`
  position: absolute;
  display: block;
  background: linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%);
  width: 100%;
  left: 0;
  height: 220%;
  top: 0px;
  transform: translate(0,-55%);
  z-index: 2;
  pointer-events: none;

  ${media.medium` 
    display: none;
  `}
`

const DiscoverIntro = ({smoother}) => {

  React.useLayoutEffect(()=> {
    let mm = gsap.matchMedia();
    let tl = null;
    mm.add("(max-width: 899px)", () => {
        
      tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#discover-gradient-mask",
            id: "discover-mask-trigger",
            start: "center 75%",
            end: "+=600",
            scrub: true,
            markers: false
        }
      });
  
      tl
      .to('#discover-gradient-mask', {
        duration: 1,
        y: "50%",
      })
    
      return () => { // optional
        const triggerInstance = ScrollTrigger.getById("discover-mask-trigger");

        if(triggerInstance) {
          triggerInstance.kill();
        }

        if(tl) {
          tl.kill();
        }
      };

    })

    return () => {
      const triggerInstance = ScrollTrigger.getById("discover-mask-trigger");

        if(triggerInstance) {
          triggerInstance.kill();
        }

        if(tl) {
          tl.kill();
        }
    }

  },[]);

  React.useEffect(()=> {
    let mm = gsap.matchMedia();
    if(smoother) {
        mm.add("(min-width: 900px)", () => {
        
          const split = new SplitText("#discover-copy", { type: "words,chars" });
          const chars = split.chars;
    
          chars.forEach((char, i) => {
              smoother.effects(char, { speed: 1, lag: (i + 1) * 0.002 });
          });

          return () => { // optional
            split.revert();
          };
    
        })
    }
    
    
    return () => {
    
    }
    
  },[smoother]);

  return (
    <Wrapper>
      <Grid>
        <BorderCol>
        </BorderCol>
      </Grid>
      <Grid>
        <Col>
          <GradientMask id="discover-gradient-mask"/>
          <CopySection id="discover-copy">
            Our creative gamut is wide-ranging and boundary-pushing in order to bring your story to the future.
          </CopySection>
          <Link to="/about">
            <MaskButton theme="dark" noarrow sidecar>Find out more</MaskButton>
          </Link>
        </Col>
      </Grid>
    </Wrapper>
  )
}

export default DiscoverIntro;