import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { PadSection } from "components/Section/Section";
import { fontstack } from "utils/fontstack";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`

const Inner = styled.div`
  position: relative;
  display: inline-flex;
`;

const Segment = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-lg);
  line-height: 0.97;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  position: relative;
  margin: 0 20px 0 0;
  white-space: pre;

  &:nth-child(even) {
    opacity: 0.2;
  }
`;

const SegmentInversed = styled(Segment)` 
  &:nth-child(even) {
    opacity: 1;
  }
  &:nth-child(odd) {
    opacity: 0.2;
  }
`;

const Newsticker = ({segment, reversed, ...rest}) => {

  const segs = new Array(20).fill(null);

  return (
    <Wrapper>
      <Inner {...rest}>
        { segs.map((val,i) => {
            if(reversed) {
              return <SegmentInversed key={i}>{ segment }</SegmentInversed>
            }
            return <Segment key={i}>{ segment }</Segment>
        }) }
      </Inner>
    </Wrapper>
  )
}

export default Newsticker;
