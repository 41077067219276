import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SectionMargin } from "components/Section/Section";
import { gsap } from "gsap";
import { SplitText, ScrollTrigger } from "gsap/all";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";

gsap.registerPlugin(SplitText,ScrollTrigger);

const Wrapper = styled(SectionMargin)`
  position: relative;
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1;
  color: white;
  z-index: 2;

  ${media.medium`
    line-height: 1.33;
  `}
`;

const Title = styled.h2`
  ${fontstack.default}
  font-weight: 400;
  margin: 180px 0 100px;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  text-align: center;

  ${media.medium`
    margin: 6vw 0 10vw;
  `}
`

const LastCol = styled.div`
  grid-column: 1 / span 6;

  ${media.medium`
    grid-column: 3 / span 8;
  `}
`;

const RowGrid = styled.div` 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  padding: 18px 0;

  &:first-child {
    border-top: 1px solid var(--brand-white);
  }

  border-bottom: 1px solid var(--brand-white);


  ${media.medium`
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 22px;
    padding: 20px 0;
  `}
`


const WorkWith = ({nodes}) => {
  const [ Groups, setGroups ] = React.useState();

  React.useEffect(()=> {
    let mm = gsap.matchMedia();
    let tl;

    mm.add("(min-width: 900px)", () => {
      const groups = nodes.reduce((acc,curr,i)=> {
        const row = Math.floor(i / 4);
    
        if(Array.isArray(acc[row])) {
          acc[row].push(curr);
        } else {
          acc[row] = new Array(curr);
        }
    
        return acc;
      },[])


      setGroups(groups);
  
      return () => { // optional

      };

    })

    mm.add("(max-width: 899px)", () => {
      const groups = nodes.reduce((acc,curr,i)=> {
        const row = Math.floor(i / 2);
    
        if(Array.isArray(acc[row])) {
          acc[row].push(curr);
        } else {
          acc[row] = new Array(curr);
        }
    
        return acc;
      },[])


      setGroups(groups);
  
      return () => { // optional

      };

    })

    setTimeout(() => {
      tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#workwith-wrapper",
            start: "top center",
        }
      });
  
      tl.from(".brand-name", {
        duration: 0.8,
        opacity: 0,
        scale: 1.2,
        ease: "easeInOut",
        stagger: 0.1
      });
    }, 200);


    
    return () => {
      if(tl) {
        tl.kill();
      }
    } 

  },[]);


  

  return (
    <Wrapper id="workwith-wrapper">
      <Title>Selected clients:</Title>
      <Grid>
        <LastCol>
          { Groups && Groups.map((group,i) => {

            return (
              <RowGrid key={`group-${i}`}>
                {group && group.map((brand,i) => {
                  return <span className="brand-name" key={`brand-${brand}`}>{brand}</span>
                })}
              </RowGrid>
            )
          })}
        </LastCol>
      </Grid>
    </Wrapper>
  )
}

export default WorkWith;