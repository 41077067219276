import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import { ScrollTrigger } from "gsap/all";
import GraphQLErrorList from "../components/graphql-error-list";
import { SiteContext } from "context/site-context";
import Intro from "modules/Intro/Intro";
import HomeTitle from "modules/HomeTitle/HomeTitle";
import EveryoneTwo from "modules/EveryoneTwo/EveryoneTwo";
import WorkWith from "modules/WorkWith/WorkWith";
import FindOutTwo from "modules/FindOutTwo/FindOutTwo";
import CategoryMenu from "modules/CategoryMenu/CategoryMenu";
import FeaturedVideos from "modules/FeaturedVideos/FeaturedVideos";
import DiscoverIntro from "modules/DiscoverIntro/DiscoverIntro";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    home: sanityHome {
      brands
      featuredProjects {
        thumbnail {
          video
          mobileVideo
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        title
        tags {
          value
          label
        }
        category {
          label
          value
        }
        year {
          label
          value
        }
        mainVideo
        slug {
          current
        }
      }
    }
    biotech: allSanityProject(filter: {category: {label: {regex: "/^BioTech$/"}}}) {
      totalCount
    }
    tech: allSanityProject(filter: {category: {label: {regex: "/^Tech$/"}}}) {
      totalCount
    }
    create: allSanityProject(filter: {category: {label: {regex: "/^Create$/"}}}) {
      totalCount
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;
  const { isFirstAccess, setIntroComplete } = React.useContext(SiteContext);
  const [ Smoother, setSmoother ] = React.useState(false);


  React.useEffect(() => {

    if(!isFirstAccess.current) {
      setIntroComplete(true);
      ScrollTrigger.refresh(true);
    }
   
  }, []);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout setSmoother={setSmoother} hasIntro hasAnimatedLogo>
      <SEO title={"Home"} />
      { isFirstAccess.current && <Intro />}
      <HomeTitle />
      <DiscoverIntro smoother={Smoother}/>
      <CategoryMenu biotechCount={data.biotech.totalCount} techCount={data.tech.totalCount} createCount={data.create.totalCount}/>
      <FeaturedVideos nodes={home.featuredProjects}/>
      <EveryoneTwo smoother={Smoother} copy={<>From huge pharmaceutical giants to small innovative biotechnology companies.<br/>(And everything in between)</>}/>
      <WorkWith nodes={home.brands}/>
      <FindOutTwo />
    </Layout>
  );
};

export default IndexPage;
