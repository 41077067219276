import React from "react";
import styled from "styled-components";
import { fontstack } from "utils/fontstack";
import Arrow from "./assets/arrow.svg";

const Wrapper = styled.button`
  ${fontstack.default}
  position: relative;
  font-size: var(--font-size-base);
  color: ${(props) => props.theme === "light" ? "var(--brand-black)" : "var(--brand-white)"};
  position: relative;
  outline: none;
  margin: 0;
  background: transparent;
  padding: 18px 20px;
  border: 1.5px solid ${(props) => props.theme === "light" ? "#00000040" : "#FFFFFF40"};
  border-radius: 10px;
  transition: border .2s ease;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.reverse ? "row-reverse": "row"} ;

  & > svg {
    stroke: ${(props) => props.theme === "light" ? "#000000" : "#FFFFFF"};
  }
  
  &:hover {
    border: 1.5px solid transparent;
  }

  &:hover:after {
    opacity: 1;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -1.5px;
    top: -1.5px;
    z-index: 1;
    opacity: 0;
    border-radius: 10px;
    border: 1.5px solid ${(props) => props.theme === "light" ? "#000000" : "#FFFFFF"};
    filter: drop-shadow(0px 0px 8px ${(props) => props.theme === "light" ? "#000000" : "#FFFFFF"});
    transition: opacity .2s ease;
  }
  
`

const Label = styled.div`
  position: relative;
  z-index: 2;
  margin: ${(props) => props.reverse ? "0 0 0 10px": "0 10px 0 0"};
`;

const GlowButton = ({ children, reverse, theme, ...rest }) => {

  return (
    <Wrapper reverse={reverse} theme={theme} {...rest}>
      <Label reverse={reverse}>{ children }</Label>
      <Arrow />
    </Wrapper>
  )
}

export default GlowButton;
