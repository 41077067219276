import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby';
import Grid from "components/Grid/Grid";
import Video from "modules/Video/Video";
import BlurButton from "components/BlurButton/BlurButton";
import { SectionMargin, PadSection } from "components/Section/Section";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";
import { gsap } from "gsap";

const Wrapper = styled.div`
  position: relative;


  &:not(:last-child) {
    margin: 0 0 450px;
  }
  

  ${media.medium`
    margin: 0;
  `}
`

const Inner = styled(PadSection)`
  width: 100%;
  display: flex;
  align-items: center;

  ${media.medium`
    height: 80vw;
  `}
`

const VideoCol = styled.div`
  grid-column: 1 / span 6;

  ${media.medium`
    grid-column: 2 / span 10;
  `}
`

const CaptionWrapper = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-sm);
  font-weight: 400;
  color: white;
  display:flex;
  justify-content: space-between;
  margin: 20px 0 0;
`

const VideoStage = styled.div`
  position: relative;
  display: grid;
`

const VideoLayer = styled.div`
  grid-area: 1 / 1;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

const DesktopVideo = styled(Video)`
  display: none;
  ${media.medium`
    display: block;
  `}
`

const MobileVideoWrapper = styled.div`
  position: relative;
  padding-bottom: 115%;

  ${media.medium`
    display: none;
  `}
`

const MobileVideo = styled(Video)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const VideoFull = styled(VideoLayer)`
  position: relative;
  z-index: 1;
  opacity: 0;

  ${media.medium`
    pointer-events: none;
  `}
`;

const CustomVideo = styled(Video)` 
  position: relative;
  z-index: 1;
`;

const VideoBlackCover = styled.div` 
  position: absolute;
  width: 200%;
  height: 200%;
  background: rgba(0,0,0,0.8);
  left: -50%;
  top: -50%;
`

const NavControls = styled.div`
  width: 100%;
  z-index: 2;
  display: flex;
  margin: 1.8vw 0 0;

  & > div:not(:last-child) {
    margin: 0 16px 0 0;
  }
`


const HomeFeaturedVideo = ({previewSrc, previewMobileSrc, src, category, year, slug, ...rest}) => {
  const [ isPlaying, setIsPlaying ] = React.useState(false);
  const positionOnPlay = React.useRef(null);
  const previewVideo = React.useRef();
  const previewMobileVideo = React.useRef();
  const fullVideo = React.useRef();
  const fullVideoWrapper = React.useRef();
  const t1 = gsap.timeline({ paused: true })

  React.useEffect(() => {

    const onScroll = () => {
      if(isPlaying) {
        let currentPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if(currentPos < positionOnPlay.current - 100 || currentPos > positionOnPlay.current + 100 ) {
          onExit();
        }
      }
    }
    
    window.addEventListener("scroll", onScroll);
    window.addEventListener("keydown", onKeypress);

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("keydown", onKeypress);
    }
  }, [isPlaying])

  const onKeypress = ({key}) => {
    if(isPlaying) {

      if (key === "Escape") {
        onExit();
      }
    }
  }

  const onPlay = () => {
    const previewVideoObject = previewVideo.current;
    const fullVideoObject = fullVideo.current;

    previewVideoObject.pause();

    if(window.matchMedia("(min-width: 900px)").matches) {
      setIsPlaying(true);
      gsap.to(fullVideoWrapper.current, { opacity: 1, scale: 1.1, pointerEvents: "auto" })
      fullVideoObject.play();
    } else if(window.matchMedia("(max-width: 899px)").matches) {
      fullVideoObject.play();
      
    }
    
   

    positionOnPlay.current = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  const onExit = () => {
    setIsPlaying(false);
    positionOnPlay.current = null;
    const previewVideoObject = previewVideo.current;
    const fullVideoObject = fullVideo.current;

    previewVideoObject.play();
    gsap.to(fullVideoWrapper.current, { opacity: 0, scale: 1, pointerEvents: "none" })
    fullVideoObject.pause();
    fullVideoObject.currentTime = 0;
  }

  return (
    <Wrapper {...rest}>
      <Inner>
        <Grid>
          <VideoCol>
            <VideoStage>
              <VideoFull ref={fullVideoWrapper}>
                <CustomVideo ref={fullVideo} src={src} controls/>
                <VideoBlackCover onClick={onExit}/>
              </VideoFull>
              <VideoLayer>
                <DesktopVideo ref={previewVideo} src={previewSrc} playsInline autoPlay muted loop/>
                <MobileVideoWrapper>
                  <MobileVideo ref={previewMobileVideo} src={`${previewMobileSrc}#t=0.001`} playsInline autoPlay muted loop/>
                </MobileVideoWrapper>
              </VideoLayer>
            </VideoStage>
            { !isPlaying && <NavControls>
                <BlurButton onClick={onPlay}>Play</BlurButton>
                <BlurButton><Link to={`/project/${slug}`}>Read</Link></BlurButton>
              </NavControls> }
          </VideoCol>
        </Grid>
      </Inner>
    </Wrapper>
  )
}

export default HomeFeaturedVideo;