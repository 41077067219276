import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import { Link } from "gatsby";
import MaskButton from "components/MaskButton/MaskButton";
import { SectionMargin } from "components/Section/Section";
import { gsap } from "gsap";
import { media } from "utils/mediaQuery";
import { SplitText, ScrollTrigger } from "gsap/all";
import { fontstack } from "utils/fontstack";

gsap.registerPlugin(SplitText);

const Wrapper = styled(SectionMargin)`
  position: relative;
  margin: 200px 0 190px;
`;

const Col = styled.div`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  ${media.medium` 
    grid-column: 3 / span 8;
    align-items: flex-end;
  `}
`;

const BorderCol = styled.div`
  grid-column: 1 / span 6;
  height: 1px;
  background: white;

  ${media.medium` 
    grid-column: 2 / span 10;
  `}
`

const CopyWrapper = styled.div`
  ${fontstack.default}
  font-weight: 400;
  margin: 60px 0 80px;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;

  ${media.medium` 
    margin: 4vw 0 10vw;
  `}
`;

const ParathesisWrapper = styled.div`
  position: relative;
  ${fontstack.default}
  position: relative;
  display: flex;
  font-size: var(--font-size-base);
  line-height: 1.33;
  color: white;
  width: 100%;
  margin: 50px 0 0;
  z-index: 2;

  &:before {
    content: '(';
    display: inline;
    z-index: 2;
  }

  &:after {
    content: ').';
    display: inline;
    z-index: 2;
  }
`

const Line = styled.span`
  height: 1px;
  background: var(--brand-white);
  flex: 1;
  margin: 0 20px 0;
`

const ParathesisInnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  top: 15px;
  background: black;
`

const GradientMask = styled.div`
  position: absolute;
  display: block;
  background: linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%);
  width: 100%;
  left: 0;
  height: 220%;
  top: 0px;
  transform: translate(0,-55%);
  z-index: 2;
  pointer-events: none;

  ${media.medium` 
    display: none;
  `}
`

const EveryoneTwo = ({copy, smoother}) => {
  React.useLayoutEffect(()=> {
    let mm = gsap.matchMedia();
    let tl = null;
    mm.add("(max-width: 899px)", () => {
        
      tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#everyone-gradient-mask",
            id: "everyone-mask-trigger",
            start: "center center",
            end: "+=600",
            scrub: true,
            markers: false
        }
      });
  
      tl
      .to('#everyone-gradient-mask', {
        duration: 1,
        y: "50%",
      })
    
      return () => { // optional
        const triggerInstance = ScrollTrigger.getById("everyone-mask-trigger");

        if(triggerInstance) {
          triggerInstance.kill();
        }

        if(tl) {
          tl.kill();
        }
      };

    })

    return () => {
      const triggerInstance = ScrollTrigger.getById("everyone-mask-trigger");

        if(triggerInstance) {
          triggerInstance.kill();
        }

        if(tl) {
          tl.kill();
        }
    }

  },[]);

  React.useEffect(()=> {

    let mm = gsap.matchMedia();
    if(smoother) {
        mm.add("(min-width: 900px)", () => {
        
          const split = new SplitText("#copy-element", { type: "words,chars" });
          const chars = split.chars;
    
          chars.forEach((char, i) => {
              smoother.effects(char, { speed: 1, lag: (i + 1) * 0.008 });
           });

          return () => { // optional
            split.revert();
          };
    
        })
    }

    return () => {

    }
  },[smoother]);
  return (
    <Wrapper id="everyone-wrapper">
      <Grid>
        <BorderCol>
        </BorderCol>
      </Grid>
      <Grid>
        <Col>
          <GradientMask id="everyone-gradient-mask"/>
          <CopyWrapper id="copy-element">
            {copy}
          </CopyWrapper>
          <Link to="/work">
            <MaskButton theme="dark" noarrow sidecar>See all work</MaskButton>
          </Link>
        </Col>
      </Grid>
    </Wrapper>
  )
}

export default EveryoneTwo;
